import React, { useContext } from 'react';
import AppContext from "../app-context";
import {CameraStatusEnum} from "../models/enums/camera-status.enum";

const Item: React.FC<React.PropsWithChildren<{className: string; icon: string;}>> = ({ className, icon, children }) => (
    <div className="justify-start items-center gap-3 flex">
        <div className={`p-1.5 rounded-r-8 justify-center items-center flex ${className}`}>
            <i className={`${icon} text-1.5vw`} />
        </div>
        <div className="text-1vw text-dark">{children}</div>
    </div>
);

const Status = () => {
    const { context } = useContext(AppContext);
    const { hardwareName, cameraStatus, tvName } = context;

    if (!hardwareName && !cameraStatus && !tvName) {
        return null;
    }

    const cameraBackground = cameraStatus === CameraStatusEnum.ACTIVE ? 'bg-ACTIVE' : 'bg-INACTIVE';

    return (
        <div className="absolute bottom-0 right-0 bg-white rounded-status p-status justify-center items-center gap-8 inline-flex">
            {hardwareName && <Item icon="fb-icon--computer" className="text-dark">{hardwareName}</Item>}
            {cameraStatus && tvName && <Item icon="fb-icon--wall-camera" className={`${cameraBackground} text-white`}>{tvName}</Item>}
        </div>
    );
};

export default Status;
