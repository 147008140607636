import login from "../services/login";
import fetchContext from "../services/context";
import fetchTranslations from "../services/translations";

async function initApp() {
    try {
        const hash = window.location.hash.substring(1);
        const urlParams = new URLSearchParams(hash);
        const params = Object.fromEntries(urlParams.entries());

        if (params.deviceId && params.serial && params.apiKey) {
            localStorage.setItem("apiKey", params.apiKey);
            localStorage.setItem("deviceId", params.deviceId);
            localStorage.setItem("serial", params.serial);
        }

        if (!Boolean(localStorage.getItem("apiKey") && localStorage.getItem("deviceId") && localStorage.getItem("serial"))) {
            throw new Error("Missing fragment parameters");
        }

        const loginData = await login();
        localStorage.setItem("jwt", loginData.token);

        const context = await fetchContext();
        const language = context.customerDefaultLanguage;
        const messages = await fetchTranslations(language);
        const pairingInformation = loginData.code && loginData.codeExpiration ? { code: loginData.code, codeExpiration: loginData.codeExpiration } : undefined;

        return { context, messages, status: loginData.status, pairingInformation };
    } catch (error) {
        console.error(error);
        throw new Response(error as BodyInit);
    }
}

export default initApp;
