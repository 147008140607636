import restClient from ".";
import { TRANSLATIONS } from "../constants/api-url";
import {getJwtToken} from "./headers";

async function fetchTranslations(language: string) {
    try {
        const { data: messages } = await restClient.get(TRANSLATIONS, { params: { language, token: await getJwtToken() } })
        return messages as Record<string, string>;
    } catch (error: unknown) {
        throw new Response(error as BodyInit);
    }
}

export default fetchTranslations;