import restClient from ".";
import {LOGIN} from "../constants/api-url";
import {getHeaders} from "./headers";


async function login() {
    try {
        const {data} = await restClient.get(LOGIN, {headers: getHeaders()});
        return data;
    } catch (error) {
        throw new Response(error as BodyInit);
    }
}

export default login;