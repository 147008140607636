import React, { useContext } from "react";

import AppContext from "../app-context";
import getLogo from "../utils/brand";
import CurrentTimeDisplay from "./current-time-display";

const Navbar = () => {
  const { context } = useContext(AppContext);
  const logo = getLogo(context);

  return (
    <nav className="flex items-center bg-white font-bold text-mainBlack text-xl tv:text-5xl p-8">
      <p className="flex-1">{context.dealerName}</p>
      <img className="h-24 tv:h-60" src={logo} alt="Logo" />
      <p className="flex-1 text-end">
        <CurrentTimeDisplay
          year="numeric"
          month="long"
          weekday="long"
          day="numeric"
          hour="2-digit"
          minute="2-digit"
        />
      </p>
    </nav>
  );
};

export default Navbar;
