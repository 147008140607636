import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import Label from "./label";
import AppContext from "../app-context";
import { CONFIG, TIRE_STATE } from "../constants";
import {TireScanTire} from "../models/tire-scan-tire";

type TireProps = {
  tire: Partial<TireScanTire>;
}

const Tire = ({ tire }: TireProps) => {
  const { locale } = useContext(AppContext);
  const { translation, color, picture } =
  tire.recommendation ? CONFIG[tire.recommendation] : CONFIG[TIRE_STATE.ERROR];

  return (
    <div
      className={classNames("flex items-center w-full h-52", {
        "flex-row-reverse": tire.position?.index === 2,
      })}
    >
      {tire.tireLabel?.[locale] && (
          <Label
              title={tire.tireLabel[locale]}
              content={<FormattedMessage {...translation} />}
              color={color}
          />
      )}
      <img className="w-24 tv:w-60" src={picture} alt="Car" />
    </div>
  );
};

export default Tire;
