import React, {useContext, useLayoutEffect, useState} from 'react';
import {WELCOME} from "../constants";
import classNames from "classnames";
import CurrentTimeDisplay from "./current-time-display";
import Layout from "./layout";
import AppContext from "../app-context";
import CodeDisplay from "./code-display";
import useTranslations from "../hooks/use-translations";
import useWebSocket from "../hooks/use-web-socket";
import {FormattedMessage} from "react-intl";

const Login = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { context, pairingInformation, setPairingInformation } = useContext(AppContext);
    useWebSocket();
    useTranslations(context.customerDefaultLanguage);

    useLayoutEffect(() => {
        let interval = setInterval(() => {
            setActiveIndex((activeIndex) => (activeIndex + 1) % WELCOME.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const instructions = [
        { id: "login.instruction1", defaultMessage: "Go to Fleetback management" },
        { id: "login.instruction2", defaultMessage: "Select the camera to pair" },
        { id: "login.instruction3", defaultMessage: "Enter the following code" },
    ];

    return (
        <Layout className="justify-center">
            <div className="inline-flex gap-8">
                <div className="w-full h-full flex-col justify-between items-start inline-flex">
                    <div className="flex-col justify-start items-start flex">
                        {WELCOME.map((welcome, index) => (
                            <p
                                key={index}
                                className={classNames(
                                    "text-7vw leading-7vw transition-opacity duration-2000",
                                    index === activeIndex ? "opacity-100" : "opacity-40"
                                )}
                            >
                                {welcome}
                            </p>
                        ))}
                    </div>
                    <div className="justify-center items-center gap-2.5 inline-flex">
                        <div className="flex px-9 py-6 gap-3 border-white border-2 rounded-large text-[1vw]">
                            <CurrentTimeDisplay
                                year="numeric"
                                month="long"
                                weekday="long"
                                day="numeric"
                                hour="2-digit"
                                minute="2-digit"
                            />
                        </div>
                    </div>
                </div>
                <div className="px-5 py-5 bg-black/60 rounded-large backdrop-blur-sm flex flex-col justify-center items-center gap-4">
                    {context.logoPath && (
                        <div className="h-1/4 w-full flex justify-center items-center">
                            <img className="object-contain h-full w-full max-w-xs md:max-w-sm" src={context.logoPath} alt="Logo"/>
                        </div>
                    )}
                    <div className="w-full flex flex-col items-center justify-between p-4 gap-3">
                        <div className="text-center text-white text-1.5vw font-bold mb-2">
                            <FormattedMessage id="login.signIn" defaultMessage="Sign in with your computer" />
                        </div>
                        {instructions.map((instruction, index) => (
                            <div key={instruction.id} className="w-full text-white text-1vw flex gap-5">
                                <div className="w-5">{index + 1}.</div>
                                <div className="truncate"><FormattedMessage {...instruction} /></div>
                            </div>
                        ))}
                        {pairingInformation.code && pairingInformation.codeExpiration && (
                            <CodeDisplay code={pairingInformation.code} codeExpiration={pairingInformation.codeExpiration} setPairingInformation={setPairingInformation} />
                        )}
                        <div className="text-center text-white text-1vw mt-4">
                            <FormattedMessage id="login.connectedMessage" defaultMessage="When connected, this page automatically updates." />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Login;
