import jwt_decode, {JwtPayload} from "jwt-decode";
import {isBefore} from "date-fns";
import login from "./login";

export async function getJwtToken() {
    let jwt = localStorage.getItem("jwt");
    if (jwt !== null) {
        const decoded = jwt_decode<JwtPayload>(jwt);
        if (decoded.exp !== undefined) {
            const expiration = new Date(decoded.exp * 1000);

            if (isBefore(expiration, new Date())) {
                try {
                    const apiKey = localStorage.getItem('apiKey');
                    const deviceId = localStorage.getItem('deviceId');
                    const serial = localStorage.getItem('serial');
                    if (!apiKey || !deviceId || !serial) {
                        throw new Error('Missing required parameters');
                    }
                    const { token } = await login();
                    jwt = token;
                    if (jwt) {
                        localStorage.setItem("jwt", jwt);
                    }
                } catch (error) {
                    console.warn(error);
                }
            }
        }
    }
    return jwt;
}

export function getHeaders() {
    const apiKey = localStorage.getItem('apiKey');
    const deviceId = localStorage.getItem('deviceId');
    const serial = localStorage.getItem('serial');

    if (!apiKey || !deviceId || !serial) {
        throw new Error('Missing required parameters');
    }
    return {
        "x-api-key": apiKey,
        "x-api-device": deviceId,
        "x-api-serial": serial,
    }
}