import React, {useContext, useEffect} from "react";
import {Navigate, Outlet, useLoaderData} from "react-router-dom";

import Loader from "./loader";
import AppContext from "../app-context";
import type {LoaderData} from "../models/loaderData";
import {StatusEnum} from "../models/enums/status.enum";
import {HOME} from "../constants/routes";

const Root = () => {
  const { context: contextPayload, messages: messagesPayload, pairingInformation, status } = useLoaderData() as LoaderData;
  const { context, setContext, messages, setMessages, setLocale, setPairingInformation, setStatus } = useContext(AppContext);

  useEffect(() => {
    if (contextPayload?.dealerName && contextPayload.hardwareKey) {
      setContext(contextPayload);
      setMessages(messagesPayload);
      setLocale(contextPayload?.customerDefaultLanguage);
      setStatus(status);
      pairingInformation && setPairingInformation(pairingInformation);
    }
  }, [contextPayload, messagesPayload, pairingInformation, status, setContext, setLocale, setMessages, setPairingInformation, setStatus]);

  if (status === StatusEnum.PAIRED) {
    return <Navigate to={HOME}  />;
  }
  const isLoading = Object.keys(context).length === 0 || Object.keys(messages).length === 0;
  return isLoading ? <Loader /> : <Outlet />;
};

export default Root;
