import Layout from "./layout";
import loader from "../assets/Icons/loader.svg";

const Loader = () => (
  <Layout className="justify-center">
    <img src={loader} alt="icon" className="animate-spin w-1/12" />
  </Layout>
);

export default Loader;
