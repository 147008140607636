import React from "react";
import classNames from "classnames";

export const colorsMap = {
  red: "text-red",
  green: "text-green",
  orange: "text-orange",
  dark: "text-dark",
};

type LabelProps = {
  title?: React.ReactNode | string;
  content?: React.ReactNode;
  color?: string;
  reverse?: boolean;
}

type ColorKey = keyof typeof colorsMap;

const Label = ({ title, content, color = "dark", reverse }: LabelProps) => {
    const colorClass = colorsMap[color as ColorKey];

    return (
        <div
            className={classNames(
                "flex items-center text-center gap-2.5 w-full",
                reverse ? "flex-col-reverse" : "flex-col"
            )}
        >
            {title && <p className="text-1.5vw font-medium text-darkGrey">{title}</p>}
            {content !== undefined && (
                <p className={classNames('text-2vw font-bold', colorClass)}>{content}</p>

        )}
      </div>
  );
}
export default Label;
