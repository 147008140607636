import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Root from "../components/root";
import Home from "../components/home";
import Login from "../components/login";
import Summary from "../components/summary";
import ErrorNotFound from "../components/Error/error-not-found";
import { HOME, SUMMARY } from "../constants/routes";
import initialiseApplication from "./initialise-application";
import ProtectedRoute from "./protected-route";

const router = createBrowserRouter([
  {
    path: "/",
    loader: initialiseApplication,
    element: <Root />,
    errorElement: <ErrorNotFound />,
    children: [
      { index: true, element: <Login /> },
    ]
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    errorElement: <ErrorNotFound />,
    children: [
      { path: HOME, element: <Home /> },
      { path: SUMMARY, element: <Summary /> },
    ],
  }
]);

export default router;
