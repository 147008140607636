import React, { useContext } from "react";
import { IntlProvider } from "react-intl";

import AppContext from "../app-context";

type IntlWrapperProps = {
    children: React.ReactNode;
}

const IntlWrapper = ({ children }: IntlWrapperProps) => {
  const { locale, messages } = useContext(AppContext);

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export default IntlWrapper;
