import useWebSocketWithToken from "./use-websocket-with-token";
import {WS_BASE_URL} from "../constants/api-url";
import {HOME, SUMMARY} from "../constants/routes";
import {useNavigate} from "react-router-dom";
import {useCallback, useContext} from "react";
import AppContext from "../app-context";
import {getJwtToken} from "../services/headers";
import {switchCase} from "../utils/helper";
import {CameraEventEnum} from "../models/enums/camera-event.enum";
import {MessageDTO} from "../models/dto/message-dto";

const useWebSocket = () => {
    const navigate = useNavigate();
    const { setReport, context, setContext } = useContext(AppContext);

    const onUnpair = useCallback(() => {
        localStorage.removeItem("jwt");
        window.location.reload();
    }, []);

    const onPair = useCallback((data: MessageDTO) => {
        localStorage.setItem("jwt", data.token);
        setContext({...context, cameraStatus: data.status, tvName: data.name});
        navigate(`/${HOME}`);
    }, [context, setContext, navigate]);

    const onReport = useCallback((data: MessageDTO) => {
        try {
            setReport(data.report);
            navigate(SUMMARY);
        } catch (error) {
            console.error(error);
        }
    }, [navigate, setReport]);

    const onLeave = useCallback(() => {
        navigate(`/${HOME}`);
    }, [navigate]);

    const onStatusChange = useCallback((message: MessageDTO) => {
        setContext({...context, cameraStatus: message.status})
    }, [context, setContext]);

    const onMessage = useCallback((message: MessageDTO) => {
        switchCase({
            [CameraEventEnum.CAMERA_STATUS_CHANGE]: () => onStatusChange(message),
            [CameraEventEnum.VEHICLE_INTEGRATION]: () => onReport(message),
            [CameraEventEnum.VEHICLE_LEAVE]: onLeave,
            [CameraEventEnum.CAMERA_UNPAIR]: onUnpair,
            [CameraEventEnum.CAMERA_PAIR]: () => onPair(message),
        })(message.event)();
    }, [onLeave, onReport, onPair, onUnpair, onStatusChange]);

    const { closeConnection } = useWebSocketWithToken(
        WS_BASE_URL!,
        getJwtToken,
        {
            onError: (error) => console.error('WebSocket Error:', error),
            onMessage,
            reconnectInterval: 5000,
        }
    );

    window.addEventListener("beforeunload", () => {
        closeConnection();
    });

    return { closeConnection };
}

export default useWebSocket;