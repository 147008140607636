import {Report} from "../models/report";
import {LanguageEnum} from "../models/enums/language.enum";
import {StatusEnum} from "../models/enums/status.enum";
import {PairingInformations} from "../models/pairing-informations";

export const DEFAULT_CONTEXT = {
    context: {},
    locale: LanguageEnum.EN,
    report: {},
    messages: {},
    pairingInformation: {},
    status: StatusEnum.UNPAIRED,
    isTranslationsLoading: false,
    setContext: (context: any) => { },
    setLocale: (locale: string) => { },
    setReport: (report: Partial<Report>) => { },
    setMessages: (messages: any) => { },
    setIsTranslationsLoading: (isTranslationsLoading: boolean) => { },
    setPairingInformation: (pairingInformation: PairingInformations) => { },
    setStatus: (status: StatusEnum) => { }
};