import restClient from ".";
import { CONTEXT } from "../constants/api-url";
import {ContextDTO} from "../models/dto/context-dto";
import {getJwtToken} from "./headers";

async function fetchContext() {
    try {
        const { data: context } = await restClient.get(CONTEXT, {
            params: {token: await getJwtToken()}
        });
        return context as ContextDTO;
    } catch (error) {
        throw new Response(error as BodyInit);
    }
}

export default fetchContext;