export enum LanguageEnum {
    DE = 'de',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    NO = 'no',
    NL = 'nl',
    PT = 'pt',
    SV = 'sv'
}
