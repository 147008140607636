import { useContext, useEffect } from "react";

import AppContext from "../app-context";
import fetchTranslations from "../services/translations";
import {LanguageEnum} from "../models/enums/language.enum";

const useTranslations = (locale?: LanguageEnum) => {
    const { setLocale, setMessages, locale: currentLocale, setIsTranslationsLoading } = useContext(AppContext);

    useEffect(() => {
        if (locale && currentLocale !== locale) {
            setIsTranslationsLoading(true);
            fetchTranslations(locale).then((msg) => {
                setMessages(msg);
                setLocale(locale);
                setIsTranslationsLoading(false);
            }, () => setIsTranslationsLoading(false));
        }
    }, [locale, setLocale, setMessages, currentLocale, setIsTranslationsLoading]);
};

export default useTranslations;